<template>
  <div>
    <div v-show="viewMode==='grid'">
      <grid-toolbar
        :ref="gridToolbarName"
        :page-name="pageName"
        :grid-ref-name="gridName"
        :selected-rows-data="selectedRowsData"
        :title="title">
        <template slot="toolbarSlotBefore">
          <b-button-group
            v-if="gridConfig!==null && gridConfig.actionPageBtn!==undefined"
            size="sm"
          >
            <div
              v-for="(btn, index) in gridConfig.actionPageBtn"
              :key="index">
              <b-button
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'service'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :title="btn.title"
                variant="primary"
                size="sm"
                style="margin-right: 2px"
                @click="actionPageClicked(btn.command)"
              >
                <feather-icon
                  :icon="btn.icon"
                  size="12"/>
              </b-button>
            </div>
          </b-button-group>
        </template>
        <template
          v-if="gridConfig!==null && gridConfig.actionMultipleRowBtn!==undefined"
          slot="selectedRowsCommandItem">
          <div
            v-for="(btn, index) in gridConfig.actionMultipleRowBtn"
            :key="index">
            <b-dropdown-item
              v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'service'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
              @click="selectedRowsCommand(btn.command)">
              <span class="text-uppercase">{{ btn.label }}</span>
            </b-dropdown-item>
          </div>
        </template>
      </grid-toolbar>

      <dx-data-grid
        :ref="gridName"
        :on-content-ready="gridContentReady"
        :focused-column-index.sync="focusedRowIndex"
        :on-key-down="gridKeyDown"
        :on-focused-row-changing="gridFocusedRowChanging"
        :on-focused-row-changed="gridFocusedRowChanged"
        :on-focused-cell-changed="gridFocusedCellChanged"
        :on-focused-cell-changing="gridFocusedCellChanging"
        :on-selection-changed="selectionChanged"
        :height="gridHeight">
        <div
          slot="actionTpl"
          slot-scope="data">
          <b-dropdown
            :ref="gridName+'RowAction'+data.data.data.id"
            variant="link"
            no-caret
            size="sm">
            <template
              #button-content
            >
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body p-0"
              />
            </template>
            <div
              v-for="(btn, index) in gridConfig.actionRowBtn"
              :key="index">
              <b-dropdown-item
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'service'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                class=""
                @click="actionRowClicked({mode:btn.command, rowData:data.data.data})">
                <feather-icon :icon="btn.icon"/>
                <span class="align-middle ml-50 text-uppercase">{{ btn.label }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <div
          slot="hasChildrenTpl"
          slot-scope="data">
          <b-badge
            v-if="data.data.row.data.has_children"
            variant="success">
            YES
          </b-badge>
          <b-badge
            v-else
            variant="danger">
            NO
          </b-badge>
        </div>

        <dx-column-chooser mode="select"/>
      </dx-data-grid>
    </div>
    <div
      v-if="viewMode==='form'"
      class="mb-3">
      <form-app
        :ref="formName"
        :form-data-id="formDataId"
        :title="title"
        :form-name="formName"
        :on-form-close="formOnHide"
        :on-form-add-new="formOnAddNew"
        :on-form-field-data-changed="onFormFieldDataChanged"
        :on-form-before-save="formBeforeSave"
        :form-clone-mode="formCloneMode"
        :has-upload-files="true">
        <div
          v-if="additionalFormVisible"
          slot="additionalForm">
          <dx-tab-panel
            ref="tabPanel"
            :data-source="[{title:'Working Day Availability', template: 'tab1'}, {title:'Special Day Availability', template: 'tab2'}, {title:'Image', template: 'tab3'}, {title:'Video', template: 'tab4'}]"
            :selected-index="0"
            :animation-enabled="false"
            :element-attr="{class:'mt-1 mb-1'}">
            <template #title="{ data: tabs }">
              <span>
                {{ tabs.title }}
              </span>
            </template>
            <template #tab1>
              <div>
                <div class="row d-flex align-items-center">
                  <div class="col-md-5">
                    <div class="row">
                      <div class="col-md-12">
                        <label style="width: 50px;">From:</label>
                        <vue-timepicker
                          v-model="from"
                          :minute-interval="15"
                          format="HH:mm"/>
                      </div>
                      <div
                        class="col-md-12"
                        style="margin-top:10px">
                        <label style="width: 50px;">To:</label>
                        <vue-timepicker
                          v-model="to"
                          :minute-interval="15"
                          format="HH:mm"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <DxSelectBox
                      v-model="quick_day_selected"
                      :data-source="quick_day"
                      display-expr="name"
                      value-expr="id" />
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-block btn-info"
                      :disabled="quick_day_selected==''"
                      @click="quickSelect">
                      Quick Select
                    </button>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <b-alert
                      v-if="quick_day_error!==''"
                      variant="danger"
                      show
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                        {{ quick_day_error }}
                      </div>
                    </b-alert>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-12">
                    <table class="table color-table info-table color-bordered-table info-bordered-table table-bordered">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th class="text-center">
                            Monday
                          </th>
                          <th class="text-center">
                            Tuesday
                          </th>
                          <th class="text-center">
                            Wednesday
                          </th>
                          <th class="text-center">
                            Thursday
                          </th>
                          <th class="text-center">
                            Friday
                          </th>
                          <th class="text-center">
                            Saturday
                          </th>
                          <th class="text-center">
                            Sunday
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="hour">
                              Availability
                            </div>
                          </td>
                          <td>
                            <button
                              class="btn btn-block "
                              :class="{'btn-danger':!form_days.monday_available,'btn-info':form_days.monday_available}"
                              @click="toggleDay('monday')">
                              {{ monday_text }}
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-block "
                              :class="{'btn-danger':!form_days.tuesday_available,'btn-info':form_days.tuesday_available}"
                              @click="toggleDay('tuesday')">
                              {{ tuesday_text }}
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-block "
                              :class="{'btn-danger':!form_days.wednesday_available,'btn-info':form_days.wednesday_available}"
                              @click="toggleDay('wednesday')">
                              {{ wednesday_text }}
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-block "
                              :class="{'btn-danger':!form_days.thursday_available,'btn-info':form_days.thursday_available}"
                              @click="toggleDay('thursday')">
                              {{ thursday_text }}
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-block "
                              :class="{'btn-danger':!form_days.friday_available,'btn-info':form_days.friday_available}"
                              @click="toggleDay('friday')">
                              {{ friday_text }}
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-block "
                              :class="{'btn-danger':!form_days.saturday_available,'btn-info':form_days.saturday_available}"
                              @click="toggleDay('saturday')">
                              {{ saturday_text }}
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-block "
                              :class="{'btn-danger':!form_days.sunday_available,'btn-info':form_days.sunday_available}"
                              @click="toggleDay('sunday')">
                              {{ sunday_text }}
                            </button>
                          </td>
                        </tr>
                        <template v-for="n in 24" >
                          <tr
                            v-if="increment===45 && (n-1)%3===0"
                            :key="n">

                            <td><div class="hour">
                              {{ zeroPad(n-1, 2) }}:00h
                            </div></td>
                            <td>

                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'available':checkAvailability(hourFormat(n-1,increment*(n2-1)),'monday_availability'),'unavailable':!checkAvailability(hourFormat(n-1,increment*(n2-1)),'monday_availability'),'inactive':!form_days.monday_available}"
                                @click="toggleSlot('monday',hourFormat(n-1,increment*(n2-1)))">
                                {{ hourFormat(n-1,increment*(n2-1)) }}
                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'available':checkAvailability(hourFormat(n-1,increment*(n2-1)),'tuesday_availability'),'unavailable':!checkAvailability(hourFormat(n-1,increment*(n2-1)),'tuesday_availability'),'inactive':!form_days.tuesday_available}"
                                @click="toggleSlot('tuesday',hourFormat(n-1,increment*(n2-1)))">
                                {{ hourFormat(n-1,increment*(n2-1)) }}
                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'available':checkAvailability(hourFormat(n-1,increment*(n2-1)),'wednesday_availability'),'unavailable':!checkAvailability(hourFormat(n-1,increment*(n2-1)),'wednesday_availability'),'inactive':!form_days.wednesday_available}"
                                @click="toggleSlot('wednesday',hourFormat(n-1,increment*(n2-1)))">
                                {{ hourFormat(n-1,increment*(n2-1)) }}
                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'available':checkAvailability(hourFormat(n-1,increment*(n2-1)),'thursday_availability'),'unavailable':!checkAvailability(hourFormat(n-1,increment*(n2-1)),'thursday_availability'),'inactive':!form_days.thursday_available}"
                                @click="toggleSlot('thursday',hourFormat(n-1,increment*(n2-1)))">
                                {{ hourFormat(n-1,increment*(n2-1)) }}
                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'available':checkAvailability(hourFormat(n-1,increment*(n2-1)),'friday_availability'),'unavailable':!checkAvailability(hourFormat(n-1,increment*(n2-1)),'friday_availability'),'inactive':!form_days.friday_available}"
                                @click="toggleSlot('friday',hourFormat(n-1,increment*(n2-1)))">
                                {{ hourFormat(n-1,increment*(n2-1)) }}
                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'available':checkAvailability(hourFormat(n-1,increment*(n2-1)),'saturday_availability'),'unavailable':!checkAvailability(hourFormat(n-1,increment*(n2-1)),'saturday_availability'),'inactive':!form_days.saturday_available}"
                                @click="toggleSlot('saturday',hourFormat(n-1,increment*(n2-1)))">
                                {{ hourFormat(n-1,increment*(n2-1)) }}
                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'available':checkAvailability(hourFormat(n-1,increment*(n2-1)),'sunday_availability'),'unavailable':!checkAvailability(hourFormat(n-1,increment*(n2-1)),'sunday_availability'),'inactive':!form_days.sunday_available}"
                                @click="toggleSlot('sunday',hourFormat(n-1,increment*(n2-1)))">
                                {{ hourFormat(n-1,increment*(n2-1)) }}
                              </span>
                            </td>
                          </tr>
                        </template>
                        <template v-for="n in 24">
                          <tr
                            v-if="increment!==45"
                            :key="n" >

                            <td><div class="hour" >
                              {{ zeroPad(n-1, 2) }}:00h
                            </div></td>
                            <td>

                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'timeslot-full-width':increment==60,'available':checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'monday_availability'),'unavailable':!checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'monday_availability'),'inactive':!form_days.monday_available}"
                                @click="toggleSlot('monday',hourPrettify((n-1),((n2-1)*increment)))">

                                {{ hourPrettify((n-1),((n2-1)*increment)) }}

                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'timeslot-full-width':increment==60,'available':checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'tuesday_availability'),'unavailable':!checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'tuesday_availability'),'inactive':!form_days.tuesday_available}"
                                @click="toggleSlot('tuesday',hourPrettify((n-1),((n2-1)*increment)))">

                                {{ hourPrettify((n-1),((n2-1)*increment)) }}

                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'timeslot-full-width':increment==60,'available':checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'wednesday_availability'),'unavailable':!checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'wednesday_availability'),'inactive':!form_days.wednesday_available}"
                                @click="toggleSlot('wednesday',hourPrettify((n-1),((n2-1)*increment)))">

                                {{ hourPrettify((n-1),((n2-1)*increment)) }}

                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'timeslot-full-width':increment==60,'available':checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'thursday_availability'),'unavailable':!checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'thursday_availability'),'inactive':!form_days.thursday_available}"
                                @click="toggleSlot('thursday',hourPrettify((n-1),((n2-1)*increment)))">

                                {{ hourPrettify((n-1),((n2-1)*increment)) }}

                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'timeslot-full-width':increment==60,'available':checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'friday_availability'),'unavailable':!checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'friday_availability'),'inactive':!form_days.friday_available}"
                                @click="toggleSlot('friday',hourPrettify((n-1),((n2-1)*increment)))">

                                {{ hourPrettify((n-1),((n2-1)*increment)) }}

                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'timeslot-full-width':increment==60,'available':checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'saturday_availability'),'unavailable':!checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'saturday_availability'),'inactive':!form_days.saturday_available}"
                                @click="toggleSlot('saturday',hourPrettify((n-1),((n2-1)*increment)))">

                                {{ hourPrettify((n-1),((n2-1)*increment)) }}

                              </span>

                            </td>
                            <td>
                              <span
                                v-for="n2 in slots_num"
                                :key="n2"
                                class="timeslot"
                                :class="{'timeslot-full-width':increment==60,'available':checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'sunday_availability'),'unavailable':!checkAvailability(hourPrettify((n-1),((n2-1)*increment)),'sunday_availability'),'inactive':!form_days.sunday_available}"
                                @click="toggleSlot('sunday',hourPrettify((n-1),((n2-1)*increment)))">

                                {{ hourPrettify((n-1),((n2-1)*increment)) }}

                              </span>

                            </td>

                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>
            <template #tab2>
              <div>
                <div class="row">
                  <div class="col-md-8">
                    <date-picker
                      v-model="selectedDate"
                      :attributes="attributes"
                      is-inline
                      is-expanded
                      is-double-paned
                      mode="single"
                      :min-date="minDate"
                      show-caps
                      @input="selectDate" />
                  </div>
                  <div class="col-md-4">

                    <div class="selected-date">
                      <h3>Selected Date : {{ selectedDateFormated }}</h3>
                      <button
                        class="btn btn-block "
                        :class="{'btn-danger':!form_days_specific.available,'btn-info':form_days_specific.available}"
                        @click="toggleDateSpecific()">
                        {{ dayAvailableText }}
                      </button>
                      <hr>
                      <div v-if="form_days_specific.available">
                        <div class="row d-flex align-items-center">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12">
                                <label style="width: 50px;">From:</label>
                                <vue-timepicker
                                  v-model="from_day"
                                  :minute-interval="15"
                                  format="HH:mm"/>
                              </div>
                              <div
                                class="col-md-12"
                                style="margin-top:10px">
                                <label style="width: 50px;">To:</label>
                                <vue-timepicker
                                  v-model="to_day"
                                  :minute-interval="15"
                                  format="HH:mm"/>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <button
                              class="btn btn-block btn-info"
                              @click="quickSelectDay">
                              Quick Select
                            </button>
                          </div>
                        </div>
                        <b-alert
                          v-if="quick_day_specific_error!=''"
                          variant="danger"
                          show
                          class="mt-2"
                        >
                          <div class="alert-body">
                            <feather-icon
                              icon="InfoIcon"
                              class="mr-50"
                            />
                            {{ quick_day_specific_error }}
                          </div>
                        </b-alert>
                      </div>

                      <hr v-if="form_days_specific.available">
                      <template v-for="n in 24">
                        <div
                          v-if="increment!==45"
                          :key="n"
                          class="row"
                          style="padding-left:15px;padding-right:15px;">

                          <template v-for="n2 in slots_num">
                            <div
                              :key="n2"
                              :class="'col-md-'+12/slots_num"
                              style="padding-right:0px;padding-left:0px">

                              <span
                                class="timeslot-special"
                                :class="{'available':checkAvailabilityDay(hourPrettify((n-1),((n2-1)*increment))),'unavailable':!checkAvailabilityDay(hourPrettify((n-1),((n2-1)*increment))),'inactive':!form_days_specific.available}"
                                @click="toggleDaySlotSpecific(hourPrettify((n-1),((n2-1)*increment)))">

                                {{ hourPrettify((n-1),((n2-1)*increment)) }}

                              </span>
                            </div>
                          </template>
                        </div>
                      </template>

                      <template v-for="n in 24">
                        <div
                          v-if="increment===45 && (n-1)%3===0"
                          :key="n"
                          class="row"
                          style="padding-left:15px;padding-right:15px;">

                          <template v-for="n2 in slots_num">
                            <div
                              :key="n2"
                              :class="'col-md-'+12/slots_num"
                              style="padding-right:0px;padding-left:0px">

                              <span
                                class="timeslot-special"
                                :class="{'available':checkAvailabilityDay(hourFormat(n-1,increment*(n2-1))),'unavailable':!checkAvailabilityDay(hourFormat(n-1,increment*(n2-1))),'inactive':!form_days_specific.available}"
                                @click="toggleDaySlotSpecific(hourFormat(n-1,increment*(n2-1)))">

                                {{ hourFormat(n-1,increment*(n2-1)) }}
                              </span>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #tab3>
              <div>
                <div class="mb-1">
                  <dx-file-uploader
                    ref="fileUploader"
                    :on-value-changed="filesValueChangedImage"
                    :multiple="true"
                    :show-file-list="false"
                    select-button-text="Select Image"
                    label-text=""
                    accept="image/*"
                    upload-mode="useForm"
                  />
                </div>

                <dx-tile-view
                  ref="tileViewImage"
                  :items="serviceImage"
                  :height="206"
                  :base-item-width="180"
                  :base-item-height="180"
                  :item-margin="10"
                  :active-state-enabled="false"
                  :focus-state-enabled="false"
                  :hover-state-enabled="false"
                  direction="vertical"
                  no-data-text=""
                  item-template="tile"
                >
                  <template #tile="{ data }">
                    <div
                      :id="`${formName}Foto-${data.id}`"
                      class="image border"
                      :style="{ 'background-image': `url(${data.filesrc})`, 'height': '180px', 'width': '180px', 'background-position': 'center', 'background-size': 'cover' }">
                      <b-button
                        variant="danger"
                        size="sm"
                        style="position: absolute; right: 5px; top: 5px"
                        @click.prevent.stop="imageDel(data.id)">
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50" />
                      </b-button>
                    </div>
                  </template>
                </dx-tile-view>
              </div>
            </template>
            <template #tab4>
              <div>
                <div class="mb-1">
                  <dx-button
                    text="Select Video"
                    @click="showMediaManager = true" />
                </div>
                <div class="mb-1">
                  <span v-if="serviceVideoSingle">{{ serviceVideoSingle }}</span>
                </div>
              </div>
            </template>
          </dx-tab-panel>
        </div>
      </form-app>
    </div>

    <audit-grid
      v-if="viewMode==='audit'"
      :ref="auditGridName"
      :title="title"
      :name="auditGridName"
      :hide-event="formOnHide"/>

    <media-modal
      :visible="showMediaManager"
      size="xl"
      modal-id="home"
      :centered="true"
      :scrollable="true"
      :hide-footer="true"
      @media-modal-close="showMediaManager = false"
    >
      <template slot="modalHeader">
        Media Manager
      </template>
      <template slot="modalBody">
        <media-manager
          :is-modal="true"
          :selected-event-name="selectedEventName"
          @media-modal-close="showMediaManager = false"
        />
      </template>
      <template slot="modalFooter">
        <div class="w-100"/>
      </template>
    </media-modal>
  </div>
</template>

<script>
import { DefaultPageData } from '@/modules/mixins/data'
import { nextTick } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import { DxTabPanel } from 'devextreme-vue/tab-panel'
import { DxFileUploader } from 'devextreme-vue/ui/file-uploader'
import { DxTileView } from 'devextreme-vue/ui/tile-view'
import { DxSelectBox } from 'devextreme-vue/ui/select-box'
import { DxButton } from 'devextreme-vue/ui/button'
import MediaModal from '@/views/media-manager/MediaModal.vue'
import MediaManager from '@/views/media-manager/MediaManager.vue'

import { BAlert } from 'bootstrap-vue'
import ArrayStore from 'devextreme/data/array_store'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
import VueTimepicker from 'vue2-timepicker'

const _ = require('lodash')

export default {
  components: {
    BAlert,
    DxButton,
    DxSelectBox,
    DxTabPanel,
    DxFileUploader,
    DxTileView,
    MediaModal,
    MediaManager,
    'date-picker': DatePicker,
    VueTimepicker
  },
  props: {
    pageName: {
      type: String,
      default: 'Service'
    },
    title: {
      type: String,
      default: 'Service'
    },
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 198
      }
    }
  },
  data() {
    const data = { ...DefaultPageData }
    this.$stateMerge(data, {
      viewMode: 'grid',
      gridName: `${this.pageName}Grid`,
      gridToolbarName: `${this.pageName}GridToolbar`,
      actionRowButtonName: `${this.pageName}GridActionRowBtn`,
      auditGridName: `${this.pageName}GridAudit`,
      formName: `${this.pageName}Form`,
      formOnHide: `${this.pageName}FormHideEvent`,
      formOnAddNew: `${this.pageName}FormAddNewEvent`,
      delRowsRoute: 'serviceDelBatch',
      restoreRowsRoute: 'serviceRestoreBatch',
      serviceReorderName: `${this.pageName}ReorderForm`,
      serviceImage: [],
      serviceImageRender: [],
      serviceVideo: [],
      serviceVideoRender: [],

      increment: 15,
      slots_num: 4,
      monday_text: 'Day Off',
      tuesday_text: 'Day Off',
      wednesday_text: 'Day Off',
      thursday_text: 'Day Off',
      friday_text: 'Day Off',
      saturday_text: 'Day Off',
      sunday_text: 'Day Off',
      form_days: {
        monday_available: false,
        tuesday_available: false,
        wednesday_available: false,
        thursday_available: false,
        friday_available: false,
        saturday_available: false,
        sunday_available: false,
        monday_availability: [],
        tuesday_availability: [],
        wednesday_availability: [],
        thursday_availability: [],
        friday_availability: [],
        saturday_availability: [],
        sunday_availability: []
      },
      from: {
        HH: '00',
        mm: '00'
      },
      to: {
        HH: '01',
        mm: '00'
      },
      quick_day: new ArrayStore({
        data: [
          { id: 'monday', name: 'Monday' },
          { id: 'tuesday', name: 'Tuesday' },
          { id: 'wednesday', name: 'Wednesday' },
          { id: 'thursday', name: 'Thursday' },
          { id: 'friday', name: 'Friday' },
          { id: 'saturday', name: 'Saturday' },
          { id: 'sunday', name: 'Sunday' }
        ],
        key: 'id'
      }),
      quick_day_selected: '',
      quick_day_error: '',

      from_day: {
        HH: '00',
        mm: '00'
      },
      to_day: {
        HH: '01',
        mm: '00'
      },
      form_days_specific: {
        day: new Date(),
        available: true,
        availability: []
      },
      days_marker: [],
      minDate: new Date(),
      selectedDate: new Date(),
      quick_day_specific_error: '',

      serviceVideoSingle: '',
      showMediaManager: false,
      selectedEventName: 'editor'
    })
    return data
  },
  computed: {
    attributes() {
      return this.days_marker.map(t => ({
        key: `day.${t.id}`,
        highlight: {
          backgroundColor: t.backgroundColor,
          borderColor: t.borderColor,
          borderWidth: '2px',
          borderStyle: 'solid',
          color: t.color
        },
        dates: t.date,
        customData: t
      }))
    },
    dayAvailableText() {
      if (this.form_days_specific.available) {
        return 'Working Day'
      }

      return 'Day off'
    },
    selectedDateFormated() {
      return moment(this.selectedDate).isValid() ? moment(this.selectedDate).format('MMM Do YY') : 'None'
    }
  },
  created() {
    const vm = this
    vm.gridCreated()
  },
  mounted() {
    const vm = this
    vm.gridMount()
    vm.formMount()
  },
  methods: {
    bindMoustrap() {

    },
    actionPageClicked(command) {
      if (command === 'addNew') {
        this.viewMode = 'form'
      } else if (command === 'reorder') {
        this.viewMode = 'reorder'
      }
    },
    actionRowClicked(command) {
      const vm = this
      console.log('actionRowClicked', command)
      setTimeout(() => {
        if (command.mode === 'editRow' || command.mode === 'cloneRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          // eslint-disable-next-line radix
          vm.formDataId = parseInt(command.rowData.id)
          vm.formCloneMode = command.mode === 'cloneRow'
          vm.viewMode = 'form'
        } else if (command.mode === 'deleteRow') {
          vm.delRows([command.rowData.id], false)
        } else if (command.mode === 'restoreRow') {
          vm.restoreRows([command.rowData.id], false)
        } else if (command.mode === 'auditRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          const route = `api/service/${command.rowData.id}/audit`
          vm.viewMode = 'audit'
          nextTick().then(() => {
            vm.$refs[vm.auditGridName].updateRoute(route)
          })
        } else {
          vm.msgShow('Unauthorized access', 'error')
        }
      })
    },
    selectedRowsCommand(command) {
      if (command === 'deleteRow') {
        const vm = this
        setTimeout(() => {
          vm.delRows(this.$_map(this.selectedRowsData, 'id'))
          vm.$refs[vm.gridToolbarName].$refs[`${vm.gridName}SelectedRowsButton`].hide()
        }, 200)
      }
    },
    onFormFieldDataChanged(e) {
      const formRef = this.$refs[this.formName]
      if (e.value === undefined || !formRef.formItemsSetStatus) {
        return false
      }

      if (e.dataField === 'duration') {
        this.calculateSlotsNum(e.value)
      }

      if (e.dataField === 'category') {
        if (e.value === '1TO1_COACHING') {
          formRef.form.getEditor('max_per_block').option({
            readOnly: true,
            value: 1
          })
        }
        if (e.value === 'GROUP_COACHING') {
          formRef.form.getEditor('max_per_block').option({
            readOnly: false,
            value: 0
          })
        }
      }

      if (e.dataField === 'allow_cancellation') {
        if (e.value === 1) {
          formRef.form.getEditor('cancel_before_start').option({
            readOnly: true
          })
        } else {
          formRef.form.getEditor('cancel_before_start').option({
            readOnly: false
          })
        }
      }
    },
    formMount() {
      this.serviceVideoSingle = ''

      window.eventHub.$on('media-manager-selected-editor', file => {
        // Do something with the file info...
        console.log(file.name)
        console.log(file.mimeType)
        console.log(file.relativePath)
        console.log(file.webPath)

        this.serviceVideoSingle = file.fullPath

        // Hide the Media Manager...
        this.showMediaManager = false
      })

      this.$events.$on(`${this.formName}FormMountedEvent`, () => {
        console.log(`${this.formName}FormMountedEvent`)
        nextTick().then(() => {
          this.additionalFormVisible = true
          this.resetAdditional()
          if (this.formDataId !== null) {
            this.getSchedule()
            this.getDaysMarkers()
          }

          const vm = this.$refs[this.formName]
          this.serviceImage = []
          const { formDataEdit } = vm
          if (formDataEdit !== undefined && formDataEdit !== null) {
            _.each(formDataEdit.images, val => {
              this.serviceImage.push({
                id: val.id,
                file: null,
                filename: val.file_name,
                filesrc: val.url
              })
            })
            _.each(formDataEdit.videos, val => {
              this.serviceVideo.push({
                id: val.id,
                file: null,
                filename: val.file_name,
                filesrc: val.url
              })
            })
          }
        })
      })
    },
    formBeforeSave() {
      const vm = this
      const { formData } = vm.$refs[vm.formName]

      return new Promise(done => {
        // vm.$refs[vm.formName].formData = {...formData, form_days: this.form_days, form_days_specific: this.form_days_specific}
        // console.log('formData', vm.$refs[vm.formName].formData)
        const fd = new FormData()

        _.each(formData, (val, key) => {
          fd.append(key, val)
        })

        const imagesToEdit = []
        _.each(vm.serviceImage, val => {
          if (val.file !== null && val.file !== undefined) {
            if (val.file.constructor === File) {
              fd.append('files[]', val.file)
            }
          } else {
            imagesToEdit.push(val)
          }
        })

        _.each(vm.serviceVideo, val => {
          if (val.file !== null && val.file !== undefined) {
            if (val.file.constructor === File) {
              fd.append('video', val.file)
            }
          }
        })

        fd.append('serviceVideoSingle', this.serviceVideoSingle)
        fd.append('images', JSON.stringify(imagesToEdit))
        fd.append('form_days', JSON.stringify(this.form_days))
        fd.append('form_days_specific', JSON.stringify(this.form_days_specific))

        vm.$refs[vm.formName].formDataHasUploadFiles = fd

        done(true)
      }).catch(error => {
        this.msgShow(error, 'error')
        return false
      })
    },
    filesValueChangedImage(e) {
      const vm = this
      // vm.serviceImage = []
      // vm.serviceImageRender = []
      _.each(e.value, (val, index) => {
        const reader = new FileReader()
        reader.onload = ev => {
          vm.serviceImage.push({
            id: _.uniqueId(),
            file: val,
            filename: val.name,
            filesrc: ev.target.result
          })
          this.serviceImageRender[index] = ev.target.result
        }
        reader.readAsDataURL(val)
      })
      vm.$refs.tabPanel.instance.repaint()
    },
    filesValueChangedVideo(e) {
      const vm = this
      vm.serviceVideo = []
      vm.serviceVideoRender = []
      _.each(e.value, (val, index) => {
        const reader = new FileReader()
        reader.onload = ev => {
          vm.serviceVideo.push({
            id: _.uniqueId(),
            file: val,
            filename: val.name,
            filesrc: ev.target.result
          })
          this.serviceVideoRender[index] = ev.target.result
        }
        reader.readAsDataURL(val)
      })
      vm.$refs.tabPanel.instance.repaint()
    },
    imageDel(id) {
      console.log(id)
      const vm = this
      vm.serviceImage = _.filter(vm.serviceImage, serviceImage => serviceImage.id !== id)
    },
    videoDel(id) {
      console.log(id)
      const vm = this
      vm.serviceVideo = _.filter(vm.serviceVideo, serviceVideo => serviceVideo.id !== id)
    },
    getCoach() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.coach] : null
    },
    calculateSlotsNum(increment) {
      this.increment = increment
      if (increment === 60) {
        this.slots_num = 1
      }
      if (increment === 45) {
        this.slots_num = 4
      }
      if (increment === 30) {
        this.slots_num = 2
      }
      if (increment === 15) {
        this.slots_num = 4
      }
    },
    zeroPad(num, places) {
      return String(num).padStart(places, '0')
    },
    hourPrettify(hour, minutes) {
      if (minutes === 0) {
        minutes = '00'
      }

      return `${this.zeroPad(hour, 2)}:${minutes}`
    },
    hourFormat(hour, minutes) {
      const total = ((hour * 60) + minutes)
      const finalHours = Math.trunc(total / 60)
      const finalMinutes = (total % 60) === 0 ? '00' : total % 60

      return `${ this.zeroPad(finalHours, 2) }:${ finalMinutes}`
    },
    toggleSlot(day, time) {
      if (this.form_days[`${day}_available`] === false) {
        return false
      }

      const index = this.form_days[`${day}_availability`].indexOf(time)
      if (index >= 0) {
        this.form_days[`${day}_availability`].splice(index, 1)
      } else {
        this.form_days[`${day}_availability`].push(time)
      }
    },
    toggleDateSpecific() {
      this.form_days_specific.available = !this.form_days_specific.available
    },
    toggleDaySlotSpecific(time) {
      if (this.form_days_specific.available === false) {
        return false
      }

      const index = this.form_days_specific.availability.indexOf(time)
      if (index >= 0) {
        this.form_days_specific.availability.splice(index, 1)
      } else {
        this.form_days_specific.availability.push(time)
      }
    },
    toggleDay(day) {
      this.form_days[`${day}_available`] = !this.form_days[`${day}_available`]
      this.availabilityText()
    },
    availabilityText() {
      if (!this.form_days.monday_available) {
        this.monday_text = 'Day Off'
      } else {
        this.monday_text = 'Working Day'
      }
      if (!this.form_days.tuesday_available) {
        this.tuesday_text = 'Day Off'
      } else {
        this.tuesday_text = 'Working Day'
      }
      if (!this.form_days.wednesday_available) {
        this.wednesday_text = 'Day Off'
      } else {
        this.wednesday_text = 'Working Day'
      }
      if (!this.form_days.thursday_available) {
        this.thursday_text = 'Day Off'
      } else {
        this.thursday_text = 'Working Day'
      }
      if (!this.form_days.friday_available) {
        this.friday_text = 'Day Off'
      } else {
        this.friday_text = 'Working Day'
      }
      if (!this.form_days.saturday_available) {
        this.saturday_text = 'Day Off'
      } else {
        this.saturday_text = 'Working Day'
      }
      if (!this.form_days.sunday_available) {
        this.sunday_text = 'Day Off'
      } else {
        this.sunday_text = 'Working Day'
      }
    },
    checkAvailability(time, day) {
      if (this.form_days[day] === undefined || this.form_days[day].length === 0) {
        return false
      }

      if (this.form_days[day].indexOf(time) >= 0) {
        return true
      }

      return false
    },
    checkAvailabilityDay(time) {
      if (this.form_days_specific.availability === undefined || this.form_days_specific.availability.length === 0) {
        return false
      }

      if (this.form_days_specific.availability.indexOf(time) >= 0) {
        return true
      }

      return false
    },
    quickSelect() {
      const startHour = this.from.HH
      const startMinutes = this.from.mm

      const endHour = this.to.HH
      const endMinutes = this.to.mm

      /* eslint radix: ["error", "as-needed"] */
      const start = (parseInt(startHour) * 60) + parseInt(startMinutes)
      const end = (parseInt(endHour) * 60) + parseInt(endMinutes)

      if (end > start) {
        this.quick_day_error = ''

        this.form_days[`${this.quick_day_selected}_availability`] = []
        for (let i = start; i <= end; i += this.increment) {
          const hours = Math.trunc(i / 60)
          const minutes = (i % 60 === 0) ? '00' : i % 60

          if (this.form_days[`${this.quick_day_selected}_available`]) {
            this.form_days[`${this.quick_day_selected}_availability`].push(`${this.zeroPad(hours, 2)}:${minutes}`)
          }
        }
      } else {
        this.quick_day_error = '"To" time has to be greater than "From".Please change the timeframe and try again.'
      }
    },
    quickSelectDay() {
      const startHour = this.from_day.HH
      const startMinutes = this.from_day.mm

      const endHour = this.to_day.HH
      const endMinutes = this.to_day.mm

      /* eslint radix: ["error", "as-needed"] */
      const start = (parseInt(startHour) * 60) + parseInt(startMinutes)
      const end = (parseInt(endHour) * 60) + parseInt(endMinutes)

      if (end > start) {
        this.quick_day_specific_error = ''

        this.form_days_specific.availability = []
        for (let i = start; i <= end; i += this.increment) {
          const hours = Math.trunc(i / 60)
          const minutes = (i % 60 === 0) ? '00' : i % 60

          if (this.form_days_specific.available) {
            this.form_days_specific.availability.push(`${this.zeroPad(hours, 2)}:${minutes}`)
          }
        }
      } else {
        this.quick_day_specific_error = '"To" time has to be greater than "From".Please change the timeframe and try again.'
      }
    },
    selectDate(date) {
      this.form_days_specific.day = date
      this.getSpecialSchedule(date)
    },
    getDaysMarkers() {
      axiosIns.get(`/service/${this.formDataId}/daysMarkers?rules=1`).then(resp => {
        const { data } = resp
        console.log('getDaysMarkers', data)
        console.log('getDaysMarkerss ', _.size(data))
        if (_.size(data) > 0) {
          this.days_marker = data.days_markers
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    getSchedule() {
      axiosIns.get(`/service/${this.formDataId}/schedule`).then(resp => {
        const { data } = resp

        if (_.size(data) > 0) {
          this.calculateSlotsNum(data.duration)

          this.form_days.monday_available = this.castBoolean(data.monday_available)
          this.form_days.tuesday_available = this.castBoolean(data.tuesday_available)
          this.form_days.wednesday_available = this.castBoolean(data.wednesday_available)
          this.form_days.thursday_available = this.castBoolean(data.thursday_available)
          this.form_days.friday_available = this.castBoolean(data.friday_available)
          this.form_days.saturday_available = this.castBoolean(data.saturday_available)
          this.form_days.sunday_available = this.castBoolean(data.sunday_available)

          if (data.timeslots !== undefined && data.timeslots.length !== 0) {
            data.timeslots.forEach(timeslot => {
              this.form_days[`${timeslot.day}_availability`].push(timeslot.timeslot)
            })
          } else {
            this.form_days.monday_availability = []
            this.form_days.tuesday_availability = []
            this.form_days.wednesday_availability = []
            this.form_days.thursday_availability = []
            this.form_days.friday_availability = []
            this.form_days.saturday_availability = []
            this.form_days.sunday_availability = []
          }

          this.availabilityText()
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    getSpecialSchedule(date) {
      const newDate = moment(date).format('YYYY-MM-DD')
      console.log('newData', newDate)
      axiosIns.get(`/service/${this.formDataId}/specialSchedule?day=${newDate}`).then(resp => {
        const { data } = resp

        this.form_days_specific.available = true
        this.form_days_specific.availability = []
        if (data !== undefined && _.size(data) !== 0 && data !== '') {
          this.form_days_specific.available = data.available
          if (data.special_timeslots !== undefined || data.special_timeslots.length !== 0) {
            data.special_timeslots.forEach(timeslot => {
              this.form_days_specific.availability.push(timeslot.timeslot)
            })
          } else {
            this.form_days_specific.available = true
            this.form_days_specific.availability = []
          }
        } else {
          this.form_days_specific.available = true
          this.form_days_specific.availability = []
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    testForm() {
      console.log('form_days', this.form_days)
      console.log('form_days_specific', this.form_days_specific)
    },
    resetAdditional() {
      this.increment = 15
      this.slots_num = 4

      this.form_days = {
        monday_available: false,
        tuesday_available: false,
        wednesday_available: false,
        thursday_available: false,
        friday_available: false,
        saturday_available: false,
        sunday_available: false,
        monday_availability: [],
        tuesday_availability: [],
        wednesday_availability: [],
        thursday_availability: [],
        friday_availability: [],
        saturday_availability: [],
        sunday_availability: []
      }

      this.form_days_specific = {
        day: new Date(),
        available: true,
        availability: []
      }

      this.days_marker = []
    },
    castBoolean(val) {
      return parseInt(val) !== 0
    }
  }
}
</script>

<style scoped>
.tile {
    border-radius: .5em;
    text-align: center;
    color: white;
    background: gray;
}

.inactive {
  background-color: #ddd !important;
  cursor: not-allowed !important;
  color: #ffffff;
}

.inactive:hover {
  border-color: white !important;
}

.unavailable {
  background-color: #c3dab9;
  border-color: #c3dab9;
  color: #ffffff;
}

.unavailable:hover {
  background-color: #78cd51;
  border-color: #78cd51;
  color: #ffffff;
}

.available {
  background-color: #78cd51;
  border-color: #78cd51;
  color: #ffffff;
}

.available:hover {
  background: #c3dab9;
  border-color: #c3dab9;
}

.timeslot {
  float: left;
  width: 50%;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #fff;
  cursor: pointer;
  padding: 3px;
  border-radius: 3px;
}

.timeslot-full-width {
  width: 100% !important;
}

table td {
  padding: 5px !important;
}

.hour {
  font-weight: bold;
  text-align: center;
}

.selected-date {
  border: 1px solid rgb(218, 218, 218);
  padding: 10px;
}

.timeslot-special {
  float: left;
  width: 100%;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #fff;
  cursor: pointer;
  padding: 3px;
  border-radius: 3px;
}

.legend {
  float: left;
  margin-right: 15px;
  display: block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.selected {
  background: rgb(102, 179, 204);
}

.partial {
  background: rgb(255, 178, 43);
  color: green;
}

.off {
  background: yellow;
}

.dx-tile-content {
  height: 100%;
  padding: 0;
  position: relative;
}

.dx-fileuploader-input {
  opacity: 0;
}

</style>
